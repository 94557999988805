<template>
  <v-row>
    <styling @info="setInfo"/>
    <v-col cols="12" md="6" lg="6" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row v-if="$vuetify.breakpoint.mdAndDown && logo">
            <v-col class="text-center">
              <img style="width: 35%" :src="logo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <v-btn text @click="$router.push('/login')">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $store.getters.translate("login") }}
              </v-btn>
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2 mb-5">
                {{ $store.getters.translate("set_your_password") }}
              </h2>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="password"
                  :label="$store.getters.translate('password')"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  :success="comparePasswords"/>
                <v-text-field
                  v-model="password_again"
                  :label="$store.getters.translate('password_again')"
                  required
                  outlined
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :success="comparePasswords"/>
                <v-alert v-if="!comparePasswords && password && password_again" dense outlined type="error">
                  {{ $store.getters.translate(message) }}
                </v-alert>
                <v-alert v-if="password_weak_message" dense outlined type="error">
                  {{ $store.getters.translate(password_weak_message) }}
                </v-alert>
                <v-btn
                  @click="submit"
                  :loading="loading"
                  :disabled="!valid"
                  color="info"
                  block
                  class="mt-2"
                  submit>
                  {{ $store.getters.translate("login") }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Styling from "./includable/Styling";
import defineRulesFor from "../../ability";
export default {
  name: "FullLogin",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perluta",
  components: {
    Styling,
  },
  data: () => ({
    valid: true,
    password: "",
    password_again: "",
    show1: false,
    show2: false,
    link: "",
    loading: false,
    message: "Passwords do not match",
    password_weak_message: null,
    logo: null,
  }),
  created() {
    this.link = this.$route.query.link;
    this.load();
    this.$store.dispatch("refreshTranslations");
  },
  methods: {
    load() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/checkresetpasswordlink", { temp_code: this.link })
        .then((response) => {
          if (response.data.message === "link_expired") {
            this.$toasted.error(this.$store.getters.translate("link_expired"));
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        this.$http
          .post(this.$store.getters.appUrl + "v2/auth", { temp_code: this.link, password: this.password })
          .then((response) => {
            this.loading = false;
            if (response.status == 200) {
              this.loading = true;
              this.$store.dispatch("login", {email: response.data.email, password: this.password })
              .then(() => {
                this.userVerified();
              })
              .catch(() => {
                this.loading = false;
                this.message = this.$store.getters.translate("invalid_credentials");
              });
            }
            else {
              var errorMessage = response.data.message;
              this.password_weak_message = errorMessage.password[0];
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
      }
    },
    userVerified() {
      this.$store.dispatch("verified").then((response) => {
        this.ability.update(defineRulesFor(response.data.permissions));
        if(this.getLanguage !== response.data.language) {
          this.$store.dispatch("setLanguage", response.data.language);
        }
        this.$store.dispatch("setSidebar", true);
        this.loading = false;
      })
      .catch((error) => {
        this.$toasted.error(error.response.data.message);
        this.loading = false;
      });
    },
    setInfo(data) {
      this.support_email = data.email;
      this.logo = data.logo;
    },
  },
  computed: {
    comparePasswords() {
      if (this.password && this.password_again && this.password === this.password_again) {
        return true;
      }
      return false;
    },
  },
};
</script>
